<template>
  <div>
    <section class="selfcare-title d-flex">
      <div>
        <a class="btn btn-link btn-sm" style="color: #000" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M7.97496 4.94165L2.91663 9.99998L7.97496 15.0583"
              stroke="var(--text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.0834 10H3.05835"
              stroke="var(--text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
    </section>

    <section class="p-2 terms container_wrapper">
      <div class="container container_wrapper">
        <h5>
          <strong> {{ $t("integrationetContruction") }} </strong>
        </h5>

        <h6>
          <strong>1. {{ $t("integrationetContruction") }}</strong>
        </h6>
        <ol>
          <li>{{ $t("betingRulesIntroduction") }}</li>
          <li>{{ $t("tcApplicationGeneralScope") }}</li>
          <ul>
            <li>{{ $t("tcApplicationCumulative") }}</li>
            <li>{{ $t("tcApplicationStricter") }}</li>
          </ul>
          <li>{{ $t("termsMeaning") }}</li>
        </ol>
        <h6>
          <strong> 2 {{ $t("bettingGames") }} </strong>
        </h6>

        <p>1 {{ $t("participationRequirements") }}</p>
        <p>2 {{ $t("participationApplication") }}</p>
        <p>3 {{ $t("applicationProcess") }}</p>
        <ul>
          <li>{{ $t("applicationProcessstep1") }}</li>
          <li>{{ $t("applicationProcessstep2") }}</li>
          <li>{{ $t("applicationProcessstep3") }}</li>
        </ul>
        <p>4 {{ $t("betLimitations") }}</p>
        <p>5 {{ $t("betApproval") }}</p>
        <p>6 {{ $t("eventTimingRestrictions") }}</p>
        <p>7 {{ $t("agreementAmendments") }}</p>
        <p>8 {{ $t("participationSubjectToRules") }}</p>
        <h6>
          <strong> 3 {{ $t("outcomesAndOdd") }} </strong>
        </h6>

        <p>1 {{ $t("outcomeOptions") }}</p>
        <p>2 {{ $t("multipleOutcomeResults") }}</p>
        <p>3 {{ $t("rangeOutcomeResults") }}</p>
        <p>4 {{ $t("speculativeOutcomeResults") }}</p>
        <p>5 {{ $t("subOutcomes") }}</p>

        <ul>
          <li>{{ $t("subOutcomePredictionStatus.predictionDeemed") }}</li>
          <li>{{ $t("subOutcomePredictionStatus.correctPrediction") }}</li>
          <li>{{ $t("subOutcomePredictionStatus.falsePrediction") }}</li>
          <li>{{ $t("subOutcomePredictionStatus.voidPrediction") }}</li>
          <li>{{ $t("combinedSubOutcomePredictions.correctCorrect") }}</li>
          <li>{{ $t("combinedSubOutcomePredictions.correctVoid") }}</li>
          <li>{{ $t("combinedSubOutcomePredictions.voidFalse") }}</li>
          <li>{{ $t("combinedSubOutcomePredictions.falseFalse") }}</li>
          <li>{{ $t("outcomeTrue") }}</li>
          <li>{{ $t("correctCorrect") }}</li>
          <li>{{ $t("correctVoid") }}</li>
          <li>{{ $t("voidFalse") }}</li>
          <li>{{ $t("correctVoidFormula") }}</li>
          <li>{{ $t("correctVoidFormula2") }}</li>
        </ul>
        <p>{{ $t("where") }}</p>
        <p>{{ $t("whereAO") }}</p>
        <p>{{ $t("whereIO") }}</p>

        <p>6 {{ $t("forAvoidance") }}</p>
        <p>7 {{ $t("forAvoidanceCalc") }}</p>

        <h6>
          <strong> 4 {{ $t("wageringOption") }} </strong>
        </h6>

        <p>1 {{ $t("applicationOption") }}</p>

        <ul>
          <li>{{ $t("additionalWageringOptions1") }}</li>
          <li>{{ $t("additionalWageringOptions2") }}</li>
          <li>{{ $t("additionalWageringOptions3") }}</li>
          <li>{{ $t("additionalWageringOptions4") }}</li>
          <li>{{ $t("additionalWageringOptions5") }}</li>
          <li>{{ $t("additionalWageringOptions6") }}</li>
        </ul>

        <p>2 {{ $t("additionalWageringOptions7") }}</p>
        <p>3 {{ $t("additionalWageringOptions8") }}</p>

        <h6>
          <strong> 5 {{ $t("vipBet") }} </strong>
        </h6>
        <p>1 {{ $t("vipBet1") }}</p>
        <p>2 {{ $t("vipBet2") }}</p>
        <h6>
          <strong> 6 {{ $t("settlement") }} </strong>
        </h6>
        <p>1 {{ $t("settlement1") }}</p>
        <p>2 {{ $t("settlement2") }}</p>
        <p>3 {{ $t("settlement3") }}</p>
        <ul>
          <li>{{ $t("settlement3Rules1") }}</li>
          <li>{{ $t("settlement3Rules2") }}</li>
          <li>{{ $t("settlement3Rules3") }}</li>
        </ul>
        <p>4 {{ $t("settlement4") }}</p>
        <ul>
          <li>{{ $t("settlement4Point1") }}</li>
          <li>{{ $t("settlement4Point2") }}</li>
          <li>{{ $t("settlement4Point3") }}</li>
          <li>{{ $t("settlement4Point4") }}</li>
          <li>{{ $t("settlement4Point5") }}</li>
        </ul>
        <p>5 {{ $t("settlement5") }}</p>
        <p>6 {{ $t("settlement6") }}</p>

        <h6>
          <strong> 7 {{ $t("cashOuts") }} </strong>
        </h6>
        <p>1 {{ $t("cashOutAvailability") }}</p>
        <p>2 {{ $t("cashOutApplicability") }}</p>
        <p>3 {{ $t("cashOutApplication") }}</p>
        <p>4 {{ $t("cashOutWithdrawal") }}</p>
        <h6>
          <strong> 8 {{ $t("voidPrediction") }} </strong>
        </h6>
        <p>1 {{ $t("voidPrediction1") }}</p>
        <p>2 {{ $t("voidPrediction2") }}</p>
        <ul>
          <li>{{ $t("voidPrediction2_1") }}</li>
          <li>{{ $t("voidPrediction2_2") }}</li>
        </ul>
        <p>3 {{ $t("voidPrediction3") }}</p>
        <p>4 {{ $t("voidPrediction4") }}</p>

        <h6>
          <strong>9 {{ $t("specialRules") }} </strong>
        </h6>

        <p>1 {{ $t("specialRules1") }}</p>
        <p>2 {{ $t("specialRules2") }}</p>
        <ul>
          <li>{{ $t("specialRules2_1") }}</li>
          <li>{{ $t("specialRules2_2") }}</li>
        </ul>

        <p>{{ $t("annex1") }}</p>
        <p>{{ $t("annex1Example") }}</p>
        <p>{{ $t("annex1Example1") }}</p>
        <p>{{ $t("annex1Example2") }}</p>
        <br />
        <p>{{ $t("description") }}</p>
        <br />
        <p>{{ $t("descriptionPoint") }}</p>
        <ul>
          <li>{{ $t("descriptionPoint1") }}</li>
          <li>{{ $t("descriptionPoint2") }}</li>
          <li>{{ $t("descriptionPoint3") }}</li>
        </ul>
        <p>2 {{ $t("egBettingGame") }}</p>
        <p>{{ $t("egBettingGameDesc") }}</p>
        <ul>
          <li>{{ $t("egBettingGameDesc1") }}</li>
          <li>{{ $t("egBettingGameDesc2") }}</li>
          <li>{{ $t("egBettingGameDesc3") }}</li>
        </ul>

        <p>{{ $t("description") }}</p>
        <br />
        <p>{{ $t("egPredictExactScore") }}</p>
        <p>{{ $t("egBettingGame") }}</p>
        <p>{{ $t("egPredictExactScoreDesc") }}</p>
        <ul>
          <li>{{ $t("egBettingGameDesc1") }}</li>
          <li>{{ $t("egPredictExactScoreDesc1") }}</li>
          <li>{{ $t("egPredictExactScoreDesc2") }}</li>
        </ul>
        <p>2 {{ $t("egDoubleChance") }}</p>
        <p>{{ $t("description") }}</p>
        <br />
        <p>{{ $t("ftOptionsDesc") }}</p>
        <ul>
          <li>{{ $t("ftOptionsDesc1") }}</li>
          <li>{{ $t("ftOptionsDesc2") }}</li>
          <li>{{ $t("ftOptionsDesc3") }}</li>
        </ul>
        <p>{{ $t("egBettingGame") }}</p>
        <br />
        <br />
        <p>{{ $t("participateSingles") }}</p>
        <ul>
          <li>{{ $t("participateSingles1") }}</li>
          <li>{{ $t("participateSingles2") }}</li>
        </ul>
        <p>4 {{ $t("eg4") }}</p>
        <br />
        <br />
        <p>{{ $t("eg4Options") }}</p>
        <br />
        <br />
        <p>{{ $t("description") }}</p>
        <p>{{ $t("eg4OptionsDesc") }}</p>
        <ul>
          <li>{{ $t("eg4OptionsDesc1") }}</li>
          <li>{{ $t("eg4OptionsDesc1") }}</li>
        </ul>
        <p>{{ $t("eg4OptionsDesc2If") }}</p>
        <br />
        <br />
        <p>{{ $t("egBettingGame") }}</p>
        <br />
        <p>{{ $t("eg4OptionsDesc2IfParticipate") }}</p>
        <ul>
          <li>{{ $t("eg4OptionsDesc2IfParticipate1") }}</li>
          <li>{{ $t("eg4OptionsDesc2IfParticipate2") }}</li>
          <li>{{ $t("eg4OptionsDesc2IfParticipate3") }}</li>
        </ul>

        <p>6 {{ $t("example6Rules") }}</p>
        <br />
        <br />
        <p>{{ $t("ftHandicap") }}</p>
        <p>{{ $t("ftHandicapDesc") }}</p>
        <p>{{ $t("description") }}</p>
        <br />
        <ul>
          <li>{{ $t("ftHandicapDesc1") }}</li>
          <li>{{ $t("ftHandicapDesc2") }}</li>
          <li>{{ $t("ftHandicapDesc3") }}</li>
          <li>{{ $t("ftHandicapDesc4") }}</li>
        </ul>
        <p>{{ $t("CorrectnessOfPrediction") }}</p>
        <p>{{ $t("CorrectnessOfPredictionD") }}</p>
        <p>{{ $t("egBettingGame") }}</p>
        <br />
        <p>1 {{ $t("participateSingle") }}</p>
        <ul>
          <li>{{ $t("participateSingle1") }}</li>
          <li>{{ $t("participateSingle2") }}</li>
          <li>{{ $t("participateSingle3") }}</li>
        </ul>
        <p>2 {{ $t("participateSingle22") }}</p>
        <ul>
          <li>{{ $t("participateSingle22_1") }}</li>
          <li>{{ $t("participateSingle22_2") }}</li>
          <li>{{ $t("participateSingle22_3") }}</li>
        </ul>
        <p>8 {{ $t("example8Rules") }}</p>
        <br />
        <br />
        <p>1 {{ $t("example8footballRules") }}</p>
        <br />
        <p>{{ $t("description") }}</p>
        <br />
        <p>{{ $t("example8footballRulesDesc") }}</p>
        <p>1 {{ $t("example8footballRulesDesc1") }}</p>
        <ul>
          <li>{{ $t("correctCorrectPrediction") }}</li>
          <li>{{ $t("correctVoidPrediction") }}</li>
          <li>{{ $t("voidFalsePrediction") }}</li>
          <li>{{ $t("falseFalsePrediction") }}</li>
        </ul>
        <p>2 {{ $t("example8footballRulesDesc2") }}</p>
        <ul>
          <li>{{ $t("correctCorrectPrediction") }}</li>
          <li>{{ $t("correctVoidPrediction") }}</li>
          <li>{{ $t("voidFalsePrediction") }}</li>
          <li>{{ $t("falseFalsePrediction") }}</li>
        </ul>
        <p>{{ $t("egBettingGame") }}</p>
        <br />
        <ol>
          <li>{{ $t("correctCorrectExample") }}</li>
          <li>{{ $t("correctVoidExample") }}</li>
          <li>{{ $t("voidFalseExample") }}</li>
          <li>{{ $t("falseFalseExample") }}</li>
        </ol>
        <p>3 {{ $t("teamEstimate") }}</p>
        <p>4 {{ $t("teamEstimate4") }}</p>
        <p>5 {{ $t("teamEstimate5") }}</p>
        <p>{{ $t("egBettingGame") }}</p>
        <br />
        <ul>
          <li>{{ $t("teamEstimate5_1") }}</li>
          <li>{{ $t("teamEstimate5_2") }}</li>
          <li>{{ $t("teamEstimate5_3") }}</li>
          <li>{{ $t("teamEstimate5_4") }}</li>
        </ul>
        <p>{{ $t("ANNEX_2") }}</p>
        <p>{{ $t("exampleOptions") }}</p>
        <p>1. {{ $t("exampleOptionsBetting") }}</p>
        <p>{{ $t("exampleOptionsBettingParticipate") }}</p>
        <p>{{ $t("Variant_1") }}</p>
        <ul>
          <li>{{ $t("Variant_1_outcome") }}</li>
        </ul>
        <p>{{ $t("Variant_2") }}</p>
        <ul>
          <li>{{ $t("Variant_2_outcome") }}</li>
        </ul>
        <p>{{ $t("Variant_3") }}</p>
        <ul>
          <li>{{ $t("Variant_3_outcome") }}</li>
          <li>{{ $t("Variant_3_outcome1") }}</li>
        </ul>

        <p>{{ $t("egWageringOption") }}</p>
        <br />
        <p>{{ $t("egWageringOptionParticipate") }}</p>
        <br />
        <p>{{ $t("egWageringOptionParticipate1") }}</p>
        <br />
        <p>{{ $t("egWageringOptionParticipate2") }}</p>
        <br />
        <p>{{ $t("egWageringOptionParticipate3") }}</p>
        <br />
        <p>{{ $t("Variant_1") }}</p>
        <br />
        <ul>
          <li>{{ $t("egWageringVariant_1") }}</li>
        </ul>
        <br />
        <p>{{ $t("Variant_2") }}</p>
        <br />
        <ul>
          <li>{{ $t("egWageringVariant_3") }}</li>
        </ul>
        <br />
        <p>{{ $t("Variant_3") }}</p>
        <br />
        <ul>
          <li>{{ $t("egWageringVariant_4") }}</li>
          <li>{{ $t("egWageringVariant4_2") }}</li>
        </ul>
        <br />
        <p>3 {{ $t("egWageringOption3") }}</p>
        <br />
        <p>{{ $t("egWageringOption3Participate") }}</p>
        <br />
        <p>{{ $t("egWageringOption3Participate2") }}</p>
        <br />
        <p>{{ $t("egWageringOption3Participate3") }}</p>
        <br />
        <p>{{ $t("egWageringOption3Participate4") }}</p>
        <br />
        <p>{{ $t("parleys") }}</p>
        <br />
        <p>{{ $t("Parlay_1") }}</p>
        <br />
        <ul>
          <li>{{ $t("Parlay_1_outcome") }}</li>
        </ul>
        <br />
        <p>{{ $t("Parlay_2") }}</p>
        <br />
        <ul>
          <li>{{ $t("Parlay_2_outcome") }}</li>
        </ul>
        <br />
        <p>{{ $t("Parlay_3") }}</p>
        <br />
        <ul>
          <li>{{ $t("Parlay_3_outcome") }}</li>
        </ul>
        <br />
        <p>{{ $t("Parlay_4") }}</p>
        <br />
        <ul>
          <li>{{ $t("Parlay_4_outcome") }}</li>
          <li>{{ $t("Parlay_4_outcome1") }}</li>
        </ul>
        <br />
        <p>{{ $t("Variant_1") }}</p>
        <br />
        <ul>
          <li>{{ $t("variant_1_outcome") }}</li>
        </ul>
        <br />
        <p>{{ $t("Variant_2") }}</p>
        <br />
        <ul>
          <li>{{ $t("variant_2_outcome") }}</li>
        </ul>
        <br />
        <p>{{ $t("Variant_3") }}</p>
        <br />
        <ul>
          <li>{{ $t("variant_3_outcome") }}</li>
          <li>{{ $t("variant_3_outcome1") }}</li>
        </ul>
        <br />
        <p>{{ $t("annex3") }}</p>
        <p>{{ $t("spacialRules") }}</p>
        <p>{{ $t("spacialRulesDesc") }}</p>
        <p>{{ $t("spacialRulesPurpose") }}</p>
        <br />
        <p>{{ $t("footballRule") }}</p>
        <br />
        <p>{{ $t("footballRuleDesc") }}</p>
        <p>1. {{ $t("footballRule1") }}</p>
        <p>2. {{ $t("footballRule2") }}</p>
        <ul>
          <li>{{ $t("footballRule2_1") }}</li>
          <li>{{ $t("footballRule2_2") }}</li>
        </ul>
        <p>3. {{ $t("footballRule3") }}</p>
        <p>{{ $t("footballRule3_1") }}</p>
        <p>4. {{ $t("footballRule4") }}</p>
        <p>{{ $t("footballRule4Purpose") }}</p>
        <ul>
          <li>{{ $t("cornerDescription") }}</li>
          <li>{{ $t("cornerTimeTracking") }}</li>
          <li>{{ $t("goalKickDescription") }}</li>
          <li>{{ $t("goalKickTimeTracking") }}</li>
          <li>{{ $t("throwInDescription") }}</li>
          <li>{{ $t("throwInTimeTracking") }}</li>
          <li>{{ $t("offsideDescription") }}</li>
          <li>{{ $t("offsideTimeTracking") }}</li>
          <li>{{ $t("foulDescription") }}</li>
          <li>{{ $t("foulTimeTracking") }}</li>
          <li>{{ $t("goalDescription") }}</li>
          <li>{{ $t("goalTimeTracking") }}</li>
        </ul>
        <br />
        <p>5. {{ $t("footballRule5") }}</p>
        <br />
        <p>6. {{ $t("footballRule6") }}</p>
        <br />
        <p>{{ $t("footballRule6rule") }}</p>
        <p>{{ $t("footballRule6Proposal") }}</p>
        <ul>
          <li>{{ $t("goalScored") }}</li>
          <li>{{ $t("cornerAppointed") }}</li>
          <li>{{ $t("penaltyAppointed") }}</li>
          <li>{{ $t("cardShown") }}</li>
          <li>{{ $t("noneOfTheAbove") }}</li>
        </ul>
        <br />
        <p>7. {{ $t("footballRule7") }}</p>
        <br />
        <p>{{ $t("footballRule7_1") }}</p>
        <p>{{ $t("footballRule7_2") }}</p>
        <p>{{ $t("footballRule7_3") }}</p>
        <p>{{ $t("footballRule7_4") }}</p>
        <p>{{ $t("footballRule7_5") }}</p>
        <br />
        <p>8. {{ $t("footballRule8") }}</p>
        <p>9. {{ $t("footballRule9") }}</p>
        <br />
        <br />
        <p>2. {{ $t("iceHockeyCapital") }}</p>
        <p>1. {{ $t("regularTimePredictions") }}</p>
        <p>2. {{ $t("shotsPenaltyIndividualOvertimePredictions") }}</p>
        <p>3. {{ $t("interruptedMatchesPredictions") }}</p>
        <p>4. {{ $t("comeFromBehindWinPrediction") }}</p>

        <p>3. {{ $t("tennisRule") }}</p>
        <br />
        <p>1. {{ $t("tennisRule1") }}</p>
        <p>2. {{ $t("tennisRule2") }}</p>
        <p>{{ $t("tennisRule2if") }}</p>
        <ul>
          <li>{{ $t("tennisRule2_1") }}</li>
          <li>{{ $t("tennisRule2_2") }}</li>
          <li>{{ $t("tennisRule2_3") }}</li>
        </ul>
        <br />
        <p>3. {{ $t("tennisRule3") }}</p>
        <br />

        <p>{{ $t("tennisRule3_1") }}</p>
        <p>4. {{ $t("tennisRule4") }}</p>
        <ul>
          <li>{{ $t("tennisRule4_1") }}</li>
          <li>{{ $t("tennisRule4_2") }}</li>
          <li>{{ $t("tennisRule4_3") }}</li>
          <li>{{ $t("tennisRule4_4") }}</li>
        </ul>
        <br />
        <p>5. {{ $t("tennisRule5") }}</p>
        <p>6. {{ $t("tennisRule6") }}</p>
        <p>7. {{ $t("tennisRule7") }}</p>

        <br />
        <p>4. {{ $t("baseball") }}</p>
        <br />
        <p>1. {{ $t("totalsHandicapsValidity") }}</p>
        <p>2. {{ $t("sameDayMatchPredictions") }}</p>
        <p>3. {{ $t("firstFiveInningsOutcome") }}</p>
        <p>4. {{ $t("minimumDurationValidity") }}</p>
        <p>5. {{ $t("handicapTotalsInterruption") }}</p>
        <p>6. {{ $t("pitcherSubstitutionEffect") }}</p>
        <br />

        <p>5. {{ $t("basketBall") }}</p>
        <br />
        <p>1. {{ $t("basketballPredictionsOvertime") }}</p>
        <p>2. {{ $t("performanceOutcomeCriteria") }}</p>
        <br />
        <p>6. {{ $t("volleyBall") }}</p>
        <br />
        <p>1. {{ $t("volleyBall1") }}</p>
        <ul>
          <li>{{ $t("volleyBall1_1") }}</li>
          <li>{{ $t("volleyBall1_2") }}</li>
          <li>{{ $t("volleyBall1_3") }}</li>
        </ul>
        <p>2. {{ $t("volleyBall2") }}</p>
        <p>3. {{ $t("volleyBall3") }}</p>
        <br />

        <p>7. {{ $t("beachVolleyBall") }}</p>
        <br />
        <p>1. {{ $t("beachVolleyBall1") }}</p>
        <p>{{ $t("beachVolleyBallif") }}</p>
        <ul>
          <li>{{ $t("beachVolleyBall1_1") }}</li>
          <li>{{ $t("beachVolleyBall1_2") }}</li>
          <li>{{ $t("beachVolleyBall1_3") }}</li>
        </ul>
        <p>2. {{ $t("beachVolleyBall2") }}</p>
        <ul>
          <li>{{ $t("beachVolleyBall2_1") }}</li>
        </ul>
        <br />

        <p>8. {{ $t("waterPolo") }}</p>
        <br />
        <p>1. {{ $t("waterPolo1") }}</p>
        <br />

        <p>9. {{ $t("formula1Motogp") }}</p>
        <br />
        <p>1. {{ $t("finishNotFinishOutcome") }}</p>
        <p>2. {{ $t("whichTeamIsBetterOutcome") }}</p>
        <p>3. {{ $t("whoIsHigherOutcome") }}</p>
        <ul>
          <li>{{ $t("whichTeamIsBetterOutcome1") }}</li>
          <li>{{ $t("whichTeamIsBetterOutcome2") }}</li>
        </ul>
        <p>4. {{ $t("driverPositionsDetermination") }}</p>
        <p>5. {{ $t("warmUpLapInclusion") }}</p>
        <p>6. {{ $t("winnerAndPodiumPredictionDisputes") }}</p>
        <br />

        <p>10. {{ $t("ufc") }}</p>
        <br />
        <p>1. {{ $t("ufcTypes") }}</p>
        <ul>
          <li>{{ $t("winDescription") }}</li>
          <li>{{ $t("aheadOfTimeWinDescription") }}</li>
          <li>{{ $t("fightDurationDescription") }}</li>
          <li>{{ $t("winInRoundDescription") }}</li>
          <li>{{ $t("roundChangeDescription") }}</li>
        </ul>
        <br />

        <p>11. {{ $t("boxingMartialArt") }}</p>
        <br />
        <p>1. {{ $t("boxingMartialArtTypes") }}</p>
        <ul>
          <li>{{ $t("winDescriptionB") }}</li>
          <li>{{ $t("aheadOfTimeWinDescriptionB") }}</li>
          <li>{{ $t("fightDurationDescriptionB") }}</li>
          <li>{{ $t("victoryInRoundDescription") }}</li>
          <li>{{ $t("roundChangeDescriptionB") }}</li>
        </ul>
        <br />

        <p>12. {{ $t("australianFb") }}</p>
        <br />
        <p>1. {{ $t("australianFbDesc") }}</p>
        <br />

        <p>13. {{ $t("bandy") }}</p>
        <br />
        <p>1. {{ $t("bandyPredictionsDescription") }}</p>
        <p>2. {{ $t("unfinishedMatchDescription") }}</p>
        <br />

        <p>14. {{ $t("biathlon") }}</p>
        <br />
        <p>{{ $t("biathlonDesc") }}</p>
        <br />

        <p>15. {{ $t("poolDart") }}</p>
        <br />
        <p>1. {{ $t("refusalOrDisqualificationDescription") }}</p>
        <p>2. {{ $t("matchRegulationsChangeDescription") }}</p>
        <br />

        <p>16. {{ $t("rugbyRule") }}</p>
        <br />
        <p>{{ $t("rugbyRule1") }}</p>
        <br />

        <p>17. {{ $t("golf") }}</p>
        <br />
        <p>1. {{ $t("totalFirstPlaceScoreDescription") }}</p>
        <p>2. {{ $t("totalSecondPlaceScoreDescription") }}</p>
        <p>3. {{ $t("playerScoreDescription") }}</p>
        <p>4. {{ $t("withdrawalWinnerDescription") }}</p>
        <p>5. {{ $t("disqualificationWinnerDescription") }}</p>
        <p>6. {{ $t("disqualificationDuringRoundDescription") }}</p>
        <br />

        <p>18. {{ $t("curling") }}</p>
        <br />
        <p>{{ $t("curling1") }}</p>
        <br />

        <p>19. {{ $t("dota2") }}</p>
        <br />
        <p>1. {{ $t("technicalDefeatPredictionSettlementDescription") }}</p>
        <p>2. {{ $t("technicalDefeatMapCountDescription") }}</p>
        <p>3. {{ $t("absentiaMapDescription") }}</p>
        <p>4. {{ $t("firstBloodPredictionDescription") }}</p>
        <br />

        <p>20. {{ $t("counterStrike") }}</p>
        <br />
        <p>1. {{ $t("advantageInScoreDescription") }}</p>
        <p>2. {{ $t("matchFormatChangeDescription") }}</p>
        <p>3. {{ $t("winningOnMapDescription") }}</p>
        <br />

        <p>21. {{ $t("starcraft") }}</p>
        <br />
        <p>1. {{ $t("starcraft1") }}</p>
        <br />

        <p>22. {{ $t("dotaChess") }}</p>
        <br />
        <p>1. {{ $t("dotaChess1") }}</p>
        <p>2. {{ $t("dotaChess2") }}</p>
        <ul>
          <li>{{ $t("totalPredictionDescription") }}</li>
          <li>{{ $t("handicapPredictionDescription") }}</li>
          <li>{{ $t("top3PredictionDescription") }}</li>
        </ul>
        <br />

        <p>23. {{ $t("cricketRule") }}</p>
        <br />
        <p>{{ $t("cricketRule1") }}</p>
        <br />

        <p>24. {{ $t("simulatedReality") }}</p>
        <br />
        <p>1. {{ $t("simulatedReality1") }}</p>
        <br />

        <p>25. {{ $t("virtualFb") }}</p>
        <br />
        <p>1. {{ $t("eventResultDescription") }}</p>
        <p>2. {{ $t("applicationFailureDescription") }}</p>
        <p>3. {{ $t("technicalIssuesDescription") }}</p>
        <br />

        <p>26. {{ $t("olympicGames") }}</p>
        <br />
        <p>1. {{ $t("olympicGamesWinnerWagerDescription") }}</p>
        <p>2. {{ $t("finalMedalTablePredictionDescription") }}</p>
        <p>3. {{ $t("whoIsHigherWagerDescription") }}</p>
        <p>4. {{ $t("medalsAwardedConditionDescription") }}</p>
        <br />

        <p>27. {{ $t("championLeague") }}</p>
        <br />
        <p>{{ $t("championLeagueDesc") }}</p>
        <br />

        <p>28. {{ $t("worldChampionship") }}</p>
        <br />
        <p>1. {{ $t("teamGoalsPredictionDescription") }}</p>
        <p>2. {{ $t("topScorerPredictionDescription") }}</p>
        <p>3. {{ $t("groupTournamentStatisticsConditionDescription") }}</p>
        <p>4. {{ $t("teamCompositionPredictionDescription") }}</p>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/Footer.vue";

export default {
  name: "privacy",
  components: { Footer },
  mounted: function () {
    //
  },
  methods: {
    //
  },
};
</script>

<style src="./index.css" scoped></style>
